// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyAl6Asm_f-6CJ8Kcz71iF1lZm3A5vUWvBc",
        authDomain: "trashtag-b26f1.firebaseapp.com",
        databaseURL: "https://trashtag-b26f1.firebaseio.com",
        projectId: "trashtag-b26f1",
        storageBucket: "trashtag-b26f1.appspot.com",
        messagingSenderId: "572396734165",
        appId: "1:572396734165:web:0409c2dd0a0295b5c0045c",
        measurementId: "G-GG9NCR1Q22"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
